<template>
  <div class="modal--medium">
    <h2>{{ data.question ? 'Изменить блок' : 'Новый блок' }}</h2>
    <textarea class="question" v-model="question.question"></textarea>
    <textarea v-model="question.response"></textarea>
    <div class="btns-group">
      <button class="btn-white--border" @click="$emit('closeModal')">
        Отмена
      </button>
      <button class="btn--green" @click="handleSave">Сохранить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeHelpQuestions',
  data() {
    return {
      question: {
        question: '',
        response: '',
      },
    };
  },
  computed: {
    isEnptyField() {
      return this.question.question.length && this.question.response.length;
    },
  },
  methods: {
    handleSave() {
      if (this.isEnptyField) {
        this.data.callbackModal(this.question);
      }
    },
  },
  mounted() {
    if (this.data.question) {
      this.question.question = this.data.question;
      this.question.response = this.data.response;
    }
  },
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  width: 100%;
  height: 231px;
  border: 1px solid #d9dee0;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #697b83;
  padding: 20px;
  margin-bottom: 20px;
}
.question {
  height: 93px;
  margin-bottom: 10px;
}
.btns-group {
  justify-content: flex-end;
  button:first-child {
    margin-right: 20px;
  }
}
</style>
